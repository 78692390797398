$('document').ready(function() {
    let siteLanguage = $('html').attr('lang');
    tarteaucitronCustomText = '';
    if(siteLanguage.search('fr') >= 0) {
        tarteaucitronCustomText = {
            "middleBarHead": "",
            "alertBigPrivacy": "Ce site souhaite déposer des cookies sur votre terminal afin de mesurer l’audience, analyser les performances de ce site et de partager des contenus. Vous pouvez accepter ou refuser le dépôt des cookies avec les boutons ci-dessous. Vous pouvez retirer votre consentement à tout moment en vous rendant en bas de page.",
            "alertSmall": "Gestion des cookies",
            "acceptAll": "Tout accepter",
            "personalize": "Personnaliser",
            "close": "Fermer"
        };
    }else if(siteLanguage.search('en') >= 0) {
        tarteaucitronCustomText = {
            "middleBarHead": "",
            "alertBigPrivacy": "This site wishes to place cookies on your terminal in order to measure the audience, analyze the performance of this site and share content. You can accept or refuse the deposit of cookies with the buttons below. You can withdraw your consent at any time by going to the bottom of the page.",
            "alertSmall": "Manage services",
            "personalize": "Personalize",
            "acceptAll": "OK, accept all",
            "close": "Close"
        };
    }
    
    tarteaucitron.init({
        "privacyUrl": "",
        "hashtag": "#ett",
        "cookieName": "ett",
        "orientation": "middle",
        "groupServices": false,
        "showAlertSmall": false,
        "cookieslist": true,
        "closePopup": false,
        "showIcon": false,
        "iconPosition": "BottomRight",
        "adblocker": false,
        "DenyAllCta" : true,
        "AcceptAllCta" : true,
        "highPrivacy": true,
        "handleBrowserDNTRequest": true,
        "removeCredit": true,
        "moreInfoLink": true,
        "useExternalCss": true,
        "useExternalJs": false,
        "readmoreLink": "",
        "mandatory": true
    });
    tarteaucitron.user.gtagUa = 'G-XXXXXXXXX';
    tarteaucitron.user.gtagMore = function () { /* add here your optionnal gtag() */ };
    (tarteaucitron.job = tarteaucitron.job || []).push('gtag');
    (tarteaucitron.job = tarteaucitron.job || []).push('gcmadstorage');
    (tarteaucitron.job = tarteaucitron.job || []).push('gcmanalyticsstorage');
    (tarteaucitron.job = tarteaucitron.job || []).push('gcmfunctionality');
    (tarteaucitron.job = tarteaucitron.job || []).push('gcmpersonalization');
    (tarteaucitron.job = tarteaucitron.job || []).push('gcmadsuserdata');
    (tarteaucitron.job = tarteaucitron.job || []).push('gcmsecurity');
    
    $('.ow-rich-video-trigger').click(function(e){
        e.preventDefault();
        youtubeId = extractYoutubeId($(this).attr('href'));
        iframeH = 300;
        if(parseInt($(this).closest('.ow-rich-video,.ow-simple-video').height()) > 300) {
            iframeH = parseInt($(this).closest('.ow-rich-video,.ow-simple-video').height());
        }
        if(youtubeId != false) {
            $(this).closest('.ow-rich-video,.ow-simple-video').css('height',iframeH + 'px').css('background-image','none');
            $(this).closest('.ow-rich-video,.ow-simple-video').html('<iframe width="100%" height="'+iframeH+'" src="https://www.youtube.com/embed/'+ youtubeId +'?rel=0&autoplay=1&modestbranding=1&autohide=1&showinfo=0&controls=1" frameborder="0" allowfullscreen></iframe>');
        }
        return false;
    });
    
    $('.row .columns:last-child').addClass('end');

    // affichage menu metier-bar en mode mobile/tablette
    $('.metier-bar-show').on('click touch', function(e){
        $(this).toggleClass('active');
        $('#metier-bar').slideToggle(200);
        e.preventDefault();
    });

    // actualisation menus en mode responsive
    refreshMenus();
    $(window).resize(refreshMenus);
    initBurgerMenu();
});

// Gestion du burger menu en responsive
function initBurgerMenu() {
    $('.burger').click(function () {
        if (!$(this).hasClass('active')) {
            $(this).addClass('active');
            $('body').addClass('open-menu');
            $('html, body').css('overflow', 'hidden');
        }
    });
    
    $('body').click(function(e)  {
        if($('body').hasClass('open-menu') && $(e.target).attr('class').indexOf('burger') === -1) {
            $('.burger').removeClass('active');
            $('body').removeClass('open-menu');
            $('html, body').removeAttr('style');
        }
    })
}

function extractYoutubeId(url) {
    var videoid = url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
    if(videoid != null) {
       return videoid[1];
    }
    return false;
}

function refreshMenus() {
    $('#menu-menu-top li').addClass('menu-principal-item');
}
