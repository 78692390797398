jQuery(document).ready(function() {
    
    // home, slider desktop classique (5 items affichés)
    var owlSD = null,
        owlCN = null,
        owlH = null;
    owlSD = jQuery(".ow-slider-block .owl-carousel").owlCarousel({
        'loop':true,
        'autoplay':true,
        'autoplayTimeout':2000,  
        'nav':true,
        'navText':['<','>'],
        'margin': 30,
        responsive : {
            0: {
                'items':1,
            },
            768: {
                'items': 5,
            }
        }
    });
    
    owlCN = jQuery(".ow-carriere-block .owl-carousel").owlCarousel({
        'items':1,
        'loop':true,
        'nav':true,
        'navText':['<','>'],
    });
    
    owlH = jQuery(".header-slider .owl-carousel").owlCarousel({
        'items':1,
        'loop':true,
        'autoplay':true,
        'autoplayTimeout':4000,
        'animateOut': 'fadeOut',
        'nav':false,
    });
    
});