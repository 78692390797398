jQuery(document).ready(function() { 
    var navO = '',
        navL = '';
    if(jQuery('nav.nav-anchor').size() > 0) {
        // génération du menu avec surcharge optionnelle
        navO = jQuery('nav.nav-anchor').html();
        jQuery('nav.nav-anchor').html('<ul></ul>');
        jQuery('h2.ow-title').each(function(index){
            
            if(jQuery(navO).find('li:eq('+index+')').size() > 0 && jQuery(navO).find('li:eq('+index+')').html() !== '') {
                navL = jQuery(navO).find('li:eq('+index+')').html();
            }
            else {
                navL = jQuery(this).text();
            }
            jQuery('nav.nav-anchor ul').append('<li><a href="#'+jQuery(this).attr('id')+'">'+ navL +'</a></li>');
        });
        
        // scrollTo animé au clic
        jQuery('nav.nav-anchor a').on('click', function(e) { 
            e.preventDefault();
            jQuery('html, body').animate( { scrollTop: jQuery(jQuery(this).attr('href')).closest('.panel-grid').offset().top }, 500 );
            return false;
        });
    }
    // closest('.panel-grid').scrollTo
});