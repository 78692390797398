jQuery(document).ready(function(){
    var iLoader = jQuery('.i-loader'),
        iBusy = false,
        iCurrent = 1;
    var iMaxOffset = jQuery('#i-scroll article:last').offset();
    
    jQuery('.i-loader').parent().find('nav.page-navigation').hide();
    
    jQuery(window).scroll(function(){ 
        if( typeof iMaxOffset !== 'undefined' && ( iMaxOffset.top + jQuery('#i-scroll article:last').height() - jQuery(window).height() ) && iBusy === false ) {
            iBusy = true;
            iLoader.show();
            iCurrent++;
            
            jQuery.get( document.location.href + 'page/' + iCurrent )
                    .done( function(data) { 
                        iLoader.hide();
                        jQuery('#i-scroll article',data).each(function( iIndex ){ jQuery('#i-scroll').append(jQuery(this).clone().hide().fadeIn((iIndex+1) * 500, function(){ 
                                new Foundation.Equalizer(jQuery('#i-scroll article')).applyHeight(); })); jQuery('#i-scroll article').removeClass('end'); jQuery('#i-scroll article:last-child').addClass('end'); });
                        iMaxOffset = jQuery('#i-scroll article:last').offset();
                        iBusy = false;
                        //jQuery('#i-scroll').foundation('getHeightsByRow', function(heights) { console.trace(heights); jQuery('#i-scroll').foundation('applyHeight', heights); });
                    })
                    .fail( function() {
                        iLoader.hide();
                    });
        }
    });
    
}); 