(function($) {
	const locations = [];
	let map = null;
	
	$(document).ready(function(){
		if($('.acf-map').length !== 0 || $('.agences-map').length !== 0) {
			initMap();
		}
	});
	
	async function initMap() {
		// Request needed libraries.
		const { Map, InfoWindow } = await google.maps.importLibrary("maps");
		const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary(
				"marker",
		);
		
		$el = $('#map');
		$el.find('.marker').each(function(){
			locations.push({ lat: $(this).attr('data-lat'), lng: $(this).attr('data-lng'), html: $(this).html() })
		});
		
		const args = {
			mapId: 'map',
			zoomControl: true,
			zoom		   : parseInt($el.attr('data-zoom')),
			center		 : new google.maps.LatLng(parseFloat($el.attr('data-lat')), parseFloat($el.attr('data-lng'))),
			mapTypeId	 : google.maps.MapTypeId.ROADMAP
		};
		
		// create map
		map = new google.maps.Map( document.getElementById("map"), args);
		map.maxZoom = parseInt($el.attr('data-max-zoom'));
		const infoWindow = new google.maps.InfoWindow({
			content: "",
			disableAutoPan: true,
		});
		
		const markers = locations.map((cluster, i) => {
			const parser = new DOMParser();
			if ($el.hasClass('agences-map')) {
				var pinSvgString = '<svg xmlns="http://www.w3.org/2000/svg" width="30" height="35" viewBox="0 0 30 35" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M28.8737 14.0904C28.4011 6.19045 22.08 0.00267601 14.485 0.00267601C6.91057 0.00267601 0.568864 6.18867 0.0483791 14.0868C-0.0954409 16.1197 0.185347 18.0796 0.902728 20.0715C1.54306 21.8853 2.64224 23.6687 4.16603 25.3667L13.7591 36.6785C13.9628 36.887 14.2282 37.0046 14.485 37.0046C14.7333 37.0046 15.0791 36.8888 15.2246 36.6607L24.7475 25.3773C26.2473 23.7578 27.3482 21.9726 28.0193 20.0733C28.7367 18.0796 29.0175 16.1215 28.8737 14.0904ZM14.485 20.9374C10.8056 20.9374 7.81286 17.8319 7.81286 14.0138C7.81286 10.1957 10.8056 7.0902 14.485 7.0902C18.1644 7.0902 21.1572 10.1957 21.1572 14.0138C21.1572 17.8319 18.1644 20.9374 14.485 20.9374Z" fill="#DB2032" /></svg>';
			}else {
				var pinSvgString = '<svg xmlns="http://www.w3.org/2000/svg" width="60" height="75" viewBox="0 0 60 75" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M57.7474 28.1808C56.8024 12.3809 44.16 0.00535202 28.9701 0.00535202C13.8212 0.00535202 1.13779 12.3773 0.0968192 28.1737C-0.190821 32.2395 0.370755 36.1592 1.80552 40.143C3.08619 43.7705 5.28455 47.3374 8.33213 50.7333L27.5182 73.3571C27.9257 73.774 28.4564 74.0092 28.9701 74.0092C29.4666 74.0092 30.1583 73.7775 30.4493 73.3214L49.495 50.7547C52.4946 47.5156 54.6964 43.9451 56.0387 40.1466C57.4735 36.1592 58.0351 32.2431 57.7474 28.1808ZM28.9701 41.8748C21.6114 41.8748 15.6258 35.6639 15.6258 28.0276C15.6258 20.3913 21.6114 14.1804 28.9701 14.1804C36.3288 14.1804 42.3144 20.3913 42.3144 28.0276C42.3144 35.6639 36.3288 41.8748 28.9701 41.8748Z" fill="#DB2032" /></svg>';
			}
			const pinSvg = parser.parseFromString(pinSvgString, "image/svg+xml",).documentElement;
			const position = new google.maps.LatLng( cluster.lat, cluster.lng );
			const marker = new google.maps.marker.AdvancedMarkerElement({
				position,
				content: pinSvg,
			});
			// open info window when marker is clicked
			marker.addListener("click", () => {
				infoWindow.setContent(cluster.html);
				infoWindow.open(map, marker);
			});
			return marker;
		});
		
		// Add a marker clusterer to manage the markers.
		new markerClusterer.MarkerClusterer({ markers, map });
		
		// center map
		if(markers.length > 0) {
			center_map( map );
		}
	}

	/*
	*  center_map
	*
	*  This function will center the map, showing all markers attached to this map
	*
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*
	*  @param	map (Google Map object)
	*  @return	n/a
	*/
	function center_map( map ) {
		let bounds = new google.maps.LatLngBounds();
	
		// loop through all markers and create bounds
		$.each( locations, function( i, marker ){
			const latlng = new google.maps.LatLng( marker.lat, marker.lng );
			bounds.extend( latlng );
		});
		// This is needed to set the zoom after fitbounds,
		if(map.maxZoom !== 0) {
				google.maps.event.addListener(map, 'zoom_changed', function() {
						zoomChangeBoundsListener = google.maps.event.addListener(map, 'bounds_changed', function(event) {
								if (this.getZoom() > map.maxZoom && this.initialZoom == true) {
										// Change max/min zoom here
										this.setZoom(map.maxZoom);
										this.initialZoom = false;
								}
								google.maps.event.removeListener(zoomChangeBoundsListener);
						});
				});
				map.initialZoom = true;
		}
					
		// only 1 marker?
		if( locations.length == 1 ) {
			// set center of map
				map.setCenter( bounds.getCenter() );
				// max zoom ?
				if(map.maxZoom !== 0) {
						map.setZoom( map.maxZoom );
				} else {
						map.setZoom( 9 );
				}
		} else {
			// fit to bounds
			map.fitBounds( bounds );
		}
	}

})(jQuery);