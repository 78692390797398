jQuery(document).ready(function() {
    var maxChavH = 0;
    
    jQuery( window ).resize(function() { 
        jQuery('.same-height').each(function(){
            maxChavH = 0;
            jQuery(this).find('.panel-grid-cell,.color-grey,.color-red,.color-white').css('height','auto');
            jQuery(this).find('.panel-grid-cell').each(function(){
                if(parseInt(jQuery(this).height()) > maxChavH) {
                    maxChavH = parseInt(jQuery(this).height());
                }
            });
            if(maxChavH > 0 && parseInt(jQuery(window).width()) > 767) {
               jQuery(this).find('.panel-grid-cell,.color-grey,.color-red,.color-white').css('height',maxChavH + 'px');             
            }
            else {
               jQuery(this).find('.panel-grid-cell,.color-grey,.color-red,.color-white').css('height','auto');
            }
        });
    }).trigger('resize');
    
});