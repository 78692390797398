jQuery(document).ready(function(){
    
        jQuery('.wpcf7-form-control-wrap .column-1-4').closest('.wpcf7-form-control-wrap').addClass('wrap-column-1-4');
        jQuery('.wpcf7-form-control-wrap .column-2-4').closest('.wpcf7-form-control-wrap').addClass('wrap-column-2-4');
        jQuery('.wpcf7-form-control-wrap .column-3-4').closest('.wpcf7-form-control-wrap').addClass('wrap-column-3-4');
        jQuery('.wpcf7-form-control-wrap .clearfix').closest('.wpcf7-form-control-wrap').addClass('wrap-clearfix');
    
        // FLOATING LABEL
        jQuery(document).on('focus', '.wpcf7-form input[type=text], .wpcf7-form  input[type=email], .wpcf7-form  textarea', function(e){
                var ph = jQuery(this).attr('placeholder');
                if( ph ) {
                        jQuery(this).attr('ph', jQuery(this).attr('placeholder') );
                        jQuery(this).attr('placeholder', '');
                        //jQuery(this).animate({'padding-top':'20px', 'position' : 'relative'}, 100);
                        jQuery(this).parent().prepend('<div class="floating-label floating-label-'+ jQuery(this).attr('name') +'">' + ph + '</div>');
                        jQuery(this).parent().find('.floating-label').fadeIn();
                }
        });
        jQuery(document).on('blur', '.wpcf7-form  input[type=text],.wpcf7-form  input[type=email],.wpcf7-form  textarea', function(e){
                var ph = jQuery(this).attr('ph');
                if( ph ){
                        jQuery(this).attr('placeholder', ph);
                        jQuery(this).attr('ph', '');
                        //jQuery(this).animate({'padding-top':'8px', 'position' : 'relative'}, 100);
                        jQuery(this).parent().find('.floating-label-'+ jQuery(this).attr('name') +'').remove();
                }
        });
        
        // VALIDATION
        jQuery(document).on('blur', '.wpcf7-form  input[type=text],.wpcf7-form  input[type=email],.wpcf7-form  textarea,.wpcf7-form  select', function(e){
                field_clear_error(jQuery(this));
                var inputV = jQuery(this).val();
                if(inputV == '' && jQuery(this).hasClass('wpcf7-validates-as-required')) {
                    field_set_error(jQuery(this), 'Ce champ est obligatoire.');
                }
        });
        jQuery(document).on('blur', '.wpcf7-form  input[type=email]', function(e){
                var inputV = jQuery(this).val();
                if(inputV != '' && !field_is_email(inputV)) {
                    field_set_error(jQuery(this), 'Le format de ce champ n\'est pas valide.');
                }
        });
});

function field_clear_error(elt) {
    elt.removeClass('wpcf7-not-valid');
    elt.parent().find('.wpcf7-not-valid-tip-' + elt.attr('name') ).remove();
}

function field_set_error(elt, msg) {
    elt.addClass('wpcf7-not-valid');
    elt.parent().find('.wpcf7-not-valid-tip-' + elt.attr('name') ).remove();
    elt.after('<span role="alert" class="wpcf7-not-valid-tip wpcf7-not-valid-tip-'+elt.attr('name')+'">'+ msg +'</span>');   
}

function field_is_email(email) {
  var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return regex.test(email);
}